// import React, { useState, useEffect } from "react";
// // import { LoadingOutlined } from "@ant-design/icons";
// import { message } from "antd";
// import { getRecentTop10Examinees } from "../services/statisticsService";

// const LeaderBoard = () => {
//   const [top24Examinees, setTop24Examinees] = useState([]);

//   useEffect(() => {
//     getDataTop24();
//     // getDataTotal();
//   }, []);

//   const getDataTop24 = async () => {
//     try {
//       const dataResponse = await getRecentTop10Examinees(); // Adjust this to fetch 24 entries from the backend
//       setTop24Examinees(dataResponse);
//     } catch (e) {
//       message.error("Lỗi Hệ thống, đang thực hiện kiểm tra!, Code: DTT24");
//     }
//   };

//   const LeaderBoardCom = () => {
//     if (top24Examinees.length > 0) {
//       return (
//         <div className="grid grid-cols-2 gap-4">
//           {top24Examinees
//             .sort((a, b) => a.rank - b.rank) // Sắp xếp theo thứ tự rank
//             .map((item, index) => (
//               <div
//                 key={index}
//                 className={`flex items-center justify-between gap-3 p-4 rounded shadow-md ${
//                   index === 0
//                     ? "bg-yellow-400"
//                     : index === 1
//                     ? "bg-gray-300"
//                     : index === 2
//                     ? "bg-orange-300"
//                     : "bg-gray-100"
//                 }`}
//               >
//                 <div className="flex items-center space-x-4">
//                   <span className="text-xl font-bold">{index + 1}</span>
//                   <div>
//                     <p className="text-lg font-medium">{item.name}</p>
//                     <p className="text-sm text-gray-600">{item.workUnit}</p>
//                   </div>
//                 </div>
//                 <div>
//                   <p className="text-lg font-semibold">Số điểm: {item.score}</p>
//                   <p className="text-sm text-gray-500">{item.timeTaken}</p>
//                 </div>
//               </div>
//             ))}
//         </div>
//       );
//     }
//     return <p>Không có dữ liệu</p>;
//   };

//   return (
//     <div className="container mx-auto gap-10 mt-20 font-bold">
//       {/* <div className="w-1/5 flex flex-col items-center justify-center gap-6 left-cup">
//         <div className="w-[200px] h-[60px] bg-white flex items-center justify-center flex-col rounded-md shadow">
//           <Spin indicator={<LoadingOutlined spin />} className="text-red-600" />
//           <span>Lượt thi</span>
//         </div>
//         <div className="w-[200px] h-[60px] bg-white flex items-center justify-center flex-col rounded-md shadow">
//           <span className="text-[#930000] text-xl">{totalParticipants}</span>
//           <span>Người đăng ký</span>
//         </div>
//       </div> */}
//       <div>
//         <h1 className="text-[#930000] text-4xl font-bold text-center mb-6">BẢNG XẾP HẠNG</h1>
//         <LeaderBoardCom />
//       </div>
//     </div>
//   );
// };

// export default LeaderBoard;
import React, { useState, useEffect } from "react";
import { getRecentTop10Examinees } from "../services/statisticsService";
import { message } from "antd";
const Leaderboard = () => {
  const [data, setData] = useState([]);
  const getDataTop24 = async () => {
    try {
      const dataResponse = await getRecentTop10Examinees(); // Adjust this to fetch 24 entries from the backend
      setData(dataResponse);
    } catch (e) {
      message.error("Lỗi Hệ thống, đang thực hiện kiểm tra!, Code: DTT24");
    }
  };
  useEffect(() => {
    getDataTop24();
  }, []);
  return (
    <div className="bg-gray-100 py-5 px-4">
      <div className="max-w-7xl mx-auto bg-white shadow-md rounded-lg overflow-hidden font-bold">
        <h1 className="text-red-600 text-center text-3xl font-bold py-4">
          DANH SÁCH NGƯỜI DỰ THI ĐẠT GIẢI
        </h1>
        <table className="table-auto w-full border border-gray-300">
          <thead>
            <tr className="bg-red-500 text-white">
              <th className="border border-gray-300 px-4 py-2">STT</th>
              <th className="border border-gray-300 px-4 py-2">Họ và tên</th>
              <th className="border border-gray-300 px-4 py-2">Điểm</th>
              <th className="border border-gray-300 px-4 py-2">Thời gian</th>
              <th className="border border-gray-300 px-4 py-2">Đơn vị</th>
              <th className="border border-gray-300 px-4 py-2">Dự đoán</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                } hover:bg-yellow-200 hover:text-black`}
              >
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {index + 1}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-left">
                  {item.name}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {item.score}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {item.timeTaken}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-left">
                  {item.workUnit}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {item.prediction}
                </td>
              </tr>
            ))}
            <tr className="border border-gray-300 px-4 py-2 text-center hover:bg-yellow-200">
              <td colSpan={6} className="text-center py-5">25. Giải Tập thể: Trường THCS Thị trấn Nam Giang, với Số người tham gia: 57</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Leaderboard;
