import apiService from './apiServices';

// API Login
export const login = async (cccd, password) => {
  try {
    const response = await apiService.post('/api/auth/login', { cccd, password });
    return response.data; // Trả về dữ liệu từ response
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

// API Register
export const register = async ({name, email, password, phone, workUnit, job, address, cccd}) => {
  try {
    const response = await apiService.post('/api/auth/register', {
      name, email, password, phone, workUnit, job, address, cccd
    });
    return response.data;
  } catch (error) {
    console.error('Register failed:', error);
    throw error;
  }
};

// API lấy dữ liệu người dùng hiện tại
// export const fetchUserProfile = async () => {
//   try {
//     const response = await apiService.get('/user/');
//     return response.data;
//   } catch (error) {
//     console.error('Fetch user profile failed:', error);
//     throw error;
//   }
// };
