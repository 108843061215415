import React, { useRef } from "react";
import { useNavigate } from "react-router";
import CountdownTimer from "./CountdownTimer";
import "../css/LandingPage.css";
import { useAuth } from "../context/AuthContext";
import UserMenu from "./UserMenu";
// @ts-ignore
import LeaderBoard from "./LeaderBoard.js";
import { message } from "antd";
const LandingPage = () => {
  let navigate = useNavigate();
  const targetDate = "2024-12-25T23:59:59";
  const { isAuthenticated, user } = useAuth();
  const targetRef = useRef(null);
  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const navigateToRegister = () => {
    navigate("/register");
  };
  const navigateToQuiz = () => {
    const tgDate = new Date(targetDate);
    const now = new Date();
    if (now < tgDate) {
      navigate("/pre-exam");
    } else {
      message.info("Thời gian thi đã kết thúc!");
    }
  };
  const navigateToLogin = () => {
    navigate("/login");
  };
  const MenuComponent = () => {
    return (
      <div>
        {/* Nếu user đã đăng nhập thì hiển thị UserMenu */}
        {isAuthenticated && user ? (
          <UserMenu name={user.name} />
        ) : (
          <div
            className="cursor-pointer text-white text-xl hover:text-[#FFFB6E]"
            onClick={navigateToLogin}
          >
            Đăng nhập
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <div className="bg-[#C50300] py-5">
        <div className="container mx-auto bg-black bg-opacity-30 rounded-3xl px-10">
          <div className="flex h-16 items-center justify-between">
            <ul className="flex items-center gap-5  text-xl">
              <li className="cursor-pointer text-white hover:text-[#FFFB6E]">
                Trang chủ
              </li>
              <li className="cursor-pointer text-white hover:text-[#FFFB6E]">
                Thể lệ
              </li>
              <li
                className="cursor-pointer text-white hover:text-[#FFFB6E]"
                onClick={handleScroll}
              >
                Bảng xếp hạng
              </li>
              <li
                className="cursor-pointer text-white hover:text-[#FFFB6E]"
                onClick={navigateToRegister}
              >
                Đăng ký tài khoản
              </li>
            </ul>
            <MenuComponent></MenuComponent>
          </div>
        </div>
        <div className="header"></div>
      </div>
      <div className="bg-[#930000] h-16 text-white flex items-center justify-center font-bold text-2xl">
        <h6>
          CUỘC THI TÌM HIỂU TRỰC TUYẾN “TÌM HIỂU CÔNG TÁC CẢI CÁCH HÀNH CHÍNH,
          CHUYỂN ĐỔI SỐ” NĂM 2024
        </h6>
      </div>
      <div className="py-40 main">
        <div className="container mx-auto">
          <h1 className="text-center  text-[#930000] text-4xl font-bold mb-5">
            CUỘC THI ĐÃ KẾT THÚC
          </h1>
          <div>
            <CountdownTimer targetDate={targetDate} />
            {isAuthenticated ? (
              <div className="flex justify-center gap-10 mt-10">
                <a
                  onClick={navigateToQuiz}
                  className="px-20 py-5 rounded-xl text-2xl font-bold cursor-pointer text-white bg-[#C50300] hover:text-[#FFFB6E]"
                >
                  THAM GIA
                </a>
              </div>
            ) : (
              <div className="flex justify-center gap-10 mt-10">
                <a
                  onClick={navigateToRegister}
                  className="px-20 py-5 rounded-xl text-2xl font-bold cursor-pointer text-white bg-[#C50300] hover:text-[#FFFB6E]"
                >
                  ĐĂNG KÝ TÀI KHOẢN NGAY
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bg-gray-100 py-8 px-4 flex justify-center mt-10">
      <div className="max-w-5xl w-full bg-white shadow-lg rounded-lg p-6 grid grid-cols-2 gap-4 items-center">
        <div className="text-center">
          <h2 className="text-yellow-500 text-2xl font-bold">Số người đăng ký tham gia</h2>
          <p className="text-gray-700 text-3xl font-semibold mt-2">1,628</p>
        </div>
        <div className="text-center">
          <h2 className="text-yellow-500 text-2xl font-bold">Số lượt thi</h2>
          <p className="text-gray-700 text-3xl font-semibold mt-2">5,736</p>
        </div>
      </div>
    </div>
      <div className="mb-10 container mx-auto py-10" ref={targetRef}>
        <LeaderBoard />
      </div>
      <div className="footer">
        <div className="bg-[#C50300] text-white h-52 flex items-center justify-center">
          <div className="flex gap-36 items-center container mx-auto justify-center">
            <div>
              <p>BAN TỔ CHỨC</p>
              <p>ỦY BAN NHÂN DÂN HUYỆN NAM TRỰC</p>
              <p>
                Địa chỉ: Thị trấn Nam Giang - Huyện Nam Trực - Tỉnh Nam Định
              </p>
              <p>Số điện thoại: (0228)3827051</p>
              <p>Email: ubndhuyennamtruc-namdinh@chinhphu.vn</p>
            </div>
            <div>
              <p>ĐƠN VỊ TRIỂN KHAI, PHÁT TRIỂN PHẦN MỀM</p>
              <p>Văn phòng HĐND và UBND huyện Nam Trực</p>
              <p>
                Địa chỉ: Thị trấn Nam Giang - Huyện Nam Trực - Tỉnh Nam Định
              </p>
              <p>Số điện thoại: (0228)3827051</p>
              <p>Email: ubndhuyennamtruc-namdinh@chinhphu.vn</p>
            </div>
          </div>
        </div>
        <div className="bg-[#930000] text-white h-10 flex items-center justify-center">
          Copyright © Văn phòng HĐND và UBND huyện Nam Trực 2024. Thiết kế và
          Phát triển bởi Văn phòng HĐND và UBND huyện Nam Trực
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
