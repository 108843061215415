import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { register as registerService } from '../services/authService';
import { useNavigate } from 'react-router-dom';
const RegistrationPage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const handleSubmit = async (values) => {
        // Placeholder for API call
        try {
            await registerService(values);
            message.success('Đăng ký thành công! Chuyển hướng đến trang đăng nhập...');
            navigate('/login');
        } catch (err) {
            message.error('Đăng ký thất bại. CCCD đã tồn tại hoặc thông tin không hợp lệ.');
        }
    };

    return (
        <div className='w-full h-[1000px] flex items-center justify-center bg-[url("https://img7.thuthuatphanmem.vn/uploads/2023/09/16/background-chuyen-doi-cong-nghe-so-full-hd-dep-nhat_091026308.jpg")]'>
            <div>
                <div className='container mx-auto w-[700px] h-[500px] bg-white px-10 py-10 rounded-3xl'>
                    <h2 className='text-center text-2xl font-bold mb-2'>ĐĂNG KÝ THAM GIA CUỘC THI</h2>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} className='grid grid-cols-3 gap-2'>
                        <Form.Item name="name" label="Họ tên" rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="cccd" label="Số CCCD" rules={[{ required: true, message: 'Vui lòng nhập số CCCD' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Mật khẩu"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mật khẩu!',
                                },
                                { min: 8, message: 'Vui lòng nhập mật khẩu trên 8 ký tự' },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="passwordCf"
                            label="Nhập lại Mật khẩu"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập lại mật khẩu!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Mật khẩu không trùng nhau!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item name="phone" label="Số điện thoại" rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Vui lòng nhập Email' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="address" label="Địa chỉ" rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="job" label="Nghề nghiệp" rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="workUnit" label="Đơn vị công tác" rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}>
                            <Input />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" >
                            Đăng ký
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;