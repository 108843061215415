import React, {useState} from 'react';
import { Form, Input, Button, message, Spin  } from 'antd';
import { useNavigate  } from 'react-router-dom';
import { login } from '../services/authService';
import { useAuth } from '../context/AuthContext';
const LoginPage = () => {
    const { login: authLogin } = useAuth();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)
    const handleSubmit = async (values) => {
        // Placeholder for API call
        setLoading(true);
        const { cccd, password } = values
        try {
            const data = await login(cccd, password); // Gọi API login
            const token = data.token;  // Giả sử server trả về { token: '...' }
            const user = data.userResponse;
            authLogin(token, user); // Cập nhật token vào AuthContext và localStorage
            message.success('Đăng nhập thành công, đang điều hướng về Trang chủ!');
            setTimeout(() => navigate('/'), 500);
        } catch (error) {
            message.error('Đăng nhập thất bại vui lòng kiểm tra lại thông tin tài khoản, mật khẩu');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='w-full h-[1000px] flex items-center justify-center bg-[url("https://img7.thuthuatphanmem.vn/uploads/2023/09/16/background-chuyen-doi-cong-nghe-so-full-hd-dep-nhat_091026308.jpg")]'>
            <div>
                <div className='container mx-auto w-[500px] bg-white px-10 py-10 rounded-3xl'>
                    <h2 className='text-center text-2xl font-bold mb-2'>ĐĂNG NHẬP {isLoading ? (<Spin size="small" />) : (<span></span>)} </h2>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} className='flex flex-col'>
                        <Form.Item name="cccd" label="Số CCCD" rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Mật khẩu"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập mật khẩu!',
                                },
                                { min: 8, message: 'Vui lòng nhập mật khẩu trên 8 ký tự' },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Button type="primary" htmlType="submit">
                            Đăng nhập
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;