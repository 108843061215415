import apiService from './apiServices';

// API Login
// API Register
export const getExamForUser = async () => {
  try {
    const response = await apiService.get('/api/exam');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitExam = async (data) => {
  try {
    const response = await apiService.post('/api/exam', data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const submitExamWithoutScore = async () => {
  try {
    const response = await apiService.post('/api/exam-without-score');
    return response.data;
  } catch (error) {
    throw error;
  }
}