import apiService from './apiServices';

export const getTotalParticipants = async () => {
  try {
    const response = await apiService.get('/api/statistics/total-participants');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRecentTop10Examinees = async () => {
  try {
    const response = await apiService.get('/api/statistics/recent-examinees');
    return response.data;
  } catch (error) {
    throw error;
  }
}