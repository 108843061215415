import axios from 'axios';
import { message } from 'antd';
// Tạo một Axios instance
const apiService = axios.create({
  baseURL: 'http://116.118.51.184:9000', //baseURL thành URL  server
  // baseURL: 'http://localhost:8080', //baseURL URl Localhost
});

// Thêm một interceptor để đính kèm token vào headers
apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Lấy token từ localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Đính kèm token vào header Authorization
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Thêm interceptor để xử lý response
apiService.interceptors.response.use(
  (response) => response, // Trả về response nếu thành công
  (error) => {
    // Xử lý khi token hết hạn hoặc lỗi xác thực
    if (error.response?.status === 403) {
      console.error('Unauthorized! Token expired or invalid.');
      localStorage.removeItem('token');
      window.location.href = '/'; // Chuyển về trang login
      message.error('Hết phiên đăng nhập, vui lòng đăng nhập lại')
    }
    return Promise.reject(error);
  }
);

export default apiService;
