import React, { useState, useRef, useEffect } from 'react';
import '../css/UserMenu.css';
import { useAuth } from '../context/AuthContext';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'antd';
const UserMenu = ({ name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { logout: authLogout } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);
  const logout = () => {
    authLogout();
    message.success('Đăng xuất thành công!');
    navigate('/', { state: { refresh: true } });
  }
  return (
    <div className="user-menu" ref={menuRef}>
      {/* Avatar */}
      <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} size="large" onClick={() => setIsOpen(!isOpen)}>
        {name}
      </Avatar>
      {/* Dropdown Menu */}
      {isOpen && (
        <ul className="dropdown-menu">
          <li className="dropdown-item">{name}</li>
          <li className="dropdown-item">Tài khoản</li>
          <li className="dropdown-item">Lịch sử làm bài</li>
          <li className="dropdown-item logout" onClick={logout}>
            Đăng xuất
          </li>
        </ul>
      )}
    </div>
  );
};

export default UserMenu;
