
import React, { useState, useEffect } from "react";
import { Button, Modal, Spin, Input, Row, Col, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  getExamForUser,
  submitExam,
  submitExamWithoutScore,
} from "../services/examService";
const QuizPage = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutes in seconds
  const [startTime, setStartTime] = useState(null);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [predictedParticipants, setPredictedParticipants] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isResultModalVisible, setResultModalVisible] = useState(false);
  const [quizResult, setQuizResult] = useState(null);
  const [isCheckExam, setIsCheckExam] = useState(false)
  const navigate = useNavigate();

  const getExam = async () => {
    try {
      const data = await getExamForUser();
      setQuestions(data.questions);
      setStartTime(new Date(data.startTime).getTime());
    } catch (error) {
      console.error("Error fetching exam:", error);
    }
  };

  useEffect(() => {
    getExam();
  }, []);

  useEffect(() => {
    if (startTime) {
      const now = Date.now();
      const elapsed = Math.floor((now - startTime) / 1000);
      const remainingTime = Math.max(30 * 60 - elapsed, 0);
      setTimeLeft(remainingTime);
      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            submitExamNoScore();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [startTime]);

  const submitExamNoScore = async () => {
    try {
      await submitExamWithoutScore();
      message.warning(
        "Hết thời gian làm bài, hệ thống tự động nộp bài và điều hướng về trang chủ!"
      );
      clearInterval();
      setTimeout(() => navigate("/"), 1000);
    } catch (error) {
      message.error('Lỗi hệ thống đang thực hiện kiểm tra, Code: SMENC')
    }
  };

  const handleAnswerSelect = (option) => {
    const updatedQuestions = [...questions];
    updatedQuestions[currentQuestionIndex].answer = option; // Lưu trực tiếp ký hiệu "A", "B", "C"
    setQuestions(updatedQuestions);
    // Thêm logic tự động chuyển sang câu tiếp theo
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleSubmit = () => {
    setSubmitModalVisible(true);
  };

  const handleConfirmSubmit = async () => {
    setSubmitModalVisible(false);
    await handleSubmitQuiz();
  };
  const convertAnswerKey = (input) => {
    if (input) return input.replace("option", "");
    return null;
  };
  const handleSubmitQuiz = async () => {
    if (!predictedParticipants || isNaN(predictedParticipants)) {
      Modal.error({
        title: "Lỗi",
        content: "Vui lòng nhập số liệu dự đoán hợp lệ.",
      });
      return;
    }
    setIsLoading(true);
    setIsCheckExam(true);
    const payload = {
      answers: questions.map((q) => ({
        questionId: q.questionId,
        userAnswer: convertAnswerKey(q.answer) || null,
      })),
      predictedParticipants: parseInt(predictedParticipants, 10),
    };
    try {
      const result = await submitExam(payload); // Call API to submit exam
      setQuizResult(result);
      setResultModalVisible(true);
    } catch (error) {
      Modal.error({
        title: "Lỗi khi nộp bài",
        content: "Đã xảy ra lỗi, vui lòng thử lại.",
      });
    } finally {
      setIsLoading(false);
      setIsCheckExam(false)
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="container mx-auto bg-gray-100 p-6 mt-10 rounded-xl">
      {isCheckExam ? <Spin fullscreen /> : (<></>)}
      <div className="flex justify-center items-center gap-5">
        <h1 className="text-center text-3xl">
          BÀI THI TÌM HIỂU VỀ CẢI CÁCH HÀNH CHÍNH, CHUYỂN ĐỔI SỐ NĂM 2024
        </h1>
        <div>
          <Button onClick={handleSubmit} type="primary" className="py-5 px-10 font-bold">
            Nộp bài
          </Button>
        </div>
      </div>
      <p className="text-center text-3xl">
        Thời gian còn lại: {formatTime(timeLeft)}
      </p>
      {questions.length > 0 ? (
        <Row gutter={16} className="mt-4">
          <Col span={6}>
            <h3 className="font-bold text-2xl mb-2">Danh sách câu hỏi:</h3>
            <div
              style={{ overflowY: "auto", maxHeight: "500px" }}
              className="grid grid-cols-3 gap-2"
            >
              {questions.map((q, index) => (
                <Button
                  key={q.questionId}
                  className="text-[16px]"
                  onClick={() => setCurrentQuestionIndex(index)}
                  style={{
                    display: "block",
                    height: '40px',
                    backgroundColor: q.answer ? "lightgreen" : "white",
                    border:
                      index === currentQuestionIndex ? "1px solid blue" : "none", // Viền xanh cho câu đang làm
                  }}
                >
                  Câu {index + 1}
                </Button>
              ))}
            </div>
          </Col>
          <Col span={18}>
            <div
              style={{
                border: "1px solid #ccc",
                padding: "16px",
                borderRadius: "8px",
                minHeight: "600px",
              }}
            >
              <h2 className="text-[16px]">
                Câu {currentQuestionIndex + 1}:{" "}
                {questions[currentQuestionIndex].question}
              </h2>
              <div>
                {["optionA", "optionB", "optionC"].map((key) => (
                  <Button
                    key={key}
                    className="question text-[16px]"
                    onClick={() => handleAnswerSelect(key)}
                    style={{
                      display: "block",
                      margin: "10px 0",
                      whiteSpace: "normal", // Cho phép xuống dòng
                      wordWrap: "break-word", // Ngắt từ khi quá dài
                      textAlign: "left",
                      padding: "10px",
                      minWidth: '200px',
                      height: "auto", // Chiều cao tự động theo nội dung
                      backgroundColor:
                        questions[currentQuestionIndex].answer === key
                          ? "lightgreen"
                          : "white",
                    }}
                  >
                    {questions[currentQuestionIndex][key]}
                  </Button>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <h2>Hết phiên đăng nhập, Vui lòng đăng xuất tài khoản, đăng nhập lại để lấy bài thi</h2>
      )}
      <Modal
        visible={isSubmitModalVisible}
        title="Xác nhận nộp bài"
        onCancel={() => setSubmitModalVisible(false)}
        onOk={handleConfirmSubmit}
      >
        <p>Vui lòng nhập Dự đoán số lượt thi?</p>
        <Input
          placeholder="Nhập Dự đoán số lượt thi"
          value={predictedParticipants}
          onChange={(e) => setPredictedParticipants(e.target.value)}
          type="number"
        />
      </Modal>

      <Modal
        visible={isResultModalVisible}
        title="Kết quả bài thi"
        footer={[
          <Button key="home" type="primary" onClick={() => navigate("/")}>
            Quay lại trang chủ
          </Button>,
        ]}
        onCancel={() => navigate("/")}
      >
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <div>
              <p>Chúc mừng bạn đã hoàn thành bài thi</p>
              <p>Điểm của bạn: {quizResult?.score ?? "Chưa có kết quả"}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default QuizPage;
