import React, { useState } from "react";
import { Button, Modal, Descriptions } from "antd";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router";
const PrepareStartExam = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const handleStartQuiz = () => {
    navigate("/quizz");
  };
  return (
    <div
      style={{ textAlign: "center" }}
      className="container mx-auto py-10 h-screen bg-gray-100"
    >
      <h2 className="">
        Chào mừng Ông/Bà: {user.name} đến với bài thi tìm hiểu về Cải cách hành
        chính, chuyển đổi số, Đề án 06 huyện Nam Trực năm 2024!
      </h2>
      <h1 className="text-2xl font-bold my-5">
        THÔNG TIN NGƯỜI THAM GIA DỰ THI
      </h1>
      <div className="flex flex-col">
        <div className="">
          <div className="flex justify-center items-center">
            <Descriptions column={1} bordered>
              <Descriptions.Item label="CCCD">{user.cccd}</Descriptions.Item>
              <Descriptions.Item label="Số điện thoại">
                {user.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
              <Descriptions.Item label="Địa chỉ">
                {user.address}
              </Descriptions.Item>
              <Descriptions.Item label="Đơn vị công tác">
                {user.workUnit}
              </Descriptions.Item>
              <Descriptions.Item label="Nghề nghiệp">
                {user.job}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
      <Button
        type="primary"
        onClick={() => setIsQuizStarted(true)}
        className="mt-10"
      >
        Bắt đầu làm bài thi
      </Button>
      <Modal
        visible={isQuizStarted}
        title="Xác nhận làm bài thi"
        onCancel={() => setIsQuizStarted(false)}
        footer={[
          <Button key="ok" type="primary" onClick={handleStartQuiz}>
            Đồng ý
          </Button>,
          <Button key="cancel" onClick={() => setIsQuizStarted(false)}>
            Hủy
          </Button>,
        ]}
      >
        Hãy hít một hơi thật sâu rồi ấn Ấn Đồng ý
        để vào làm bài, Ấn hủy để xem lại!
      </Modal>
    </div>
  );
};
export default PrepareStartExam;
