import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Cleanup the timer
    }, [targetDate]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <div style={timerBoxStyle}>
                <p style={numberStyle}>{timeLeft.days}</p>
                <p>Ngày</p>
            </div>
            <div style={timerBoxStyle}>
                <p style={numberStyle}>{timeLeft.hours}</p>
                <p>Giờ</p>
            </div>
            <div style={timerBoxStyle}>
                <p style={numberStyle}>{timeLeft.minutes}</p>
                <p>Phút</p>
            </div>
            <div style={timerBoxStyle}>
                <p style={numberStyle}>{timeLeft.seconds}</p>
                <p>Giây</p>
            </div>
        </div>
    );
};

// Inline styles
const timerBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    width: '180px',
    height: '180px',
};

const numberStyle = {
    fontSize: '50px',
    color: '#D10000',
    fontWeight: 'bold',
    margin: '0',
};

export default CountdownTimer;
